<template>
  <v-dialog
    v-model="show"
    max-width="500px"
  >
    <v-card>
      <v-toolbar
        flat
        dense
        dark
        color="info"
      >
        <v-toolbar-title>
          Pesquisar
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn
            icon
            small
            @click="show = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="pt-4 text-center">
        <v-row>
          <v-col>
            <v-autocomplete
              v-model="filters.customerId"
              :items="customers"
              item-text="nickname"
              item-value="id"
              label="Cliente"
              hide-details
              clearable
              :loading="loadingCustomers"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            md="6"
          >
            <v-menu
              v-model="menuStartDate"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{}">
                <validation-provider
                  v-slot="{ errors }"
                  vid="startDate"
                >
                  <v-text-field
                    v-model="formatedStartDate"
                    v-mask="'##/##/####'"
                    label="Data Início"
                    hide-details="auto"
                    append-icon="mdi-calendar"
                    clearable
                    :error-messages="errors"
                    @blur="filters.startDate = dateFormatIso(formatedStartDate)"
                    @click:append="menuStartDate = true"
                  />
                </validation-provider>
              </template>
              <v-date-picker
                v-model="filters.startDate"
                no-title
                class="ma-0"
                @input="menuStartDate = false"
              />
            </v-menu>
          </v-col>
          <v-col
            md="6"
          >
            <v-menu
              v-model="menuEndDate"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{}">
                <validation-provider
                  v-slot="{ errors }"
                  vid="endDate"
                >
                  <v-text-field
                    v-model="formatedEndDate"
                    v-mask="'##/##/####'"
                    label="Data Fim"
                    hide-details="auto"
                    append-icon="mdi-calendar"
                    clearable
                    :error-messages="errors"
                    @blur="filters.endDate = dateFormatIso(formatedEndDate)"
                    @click:append="menuEndDate = true"
                  />
                </validation-provider>
              </template>
              <v-date-picker
                v-model="filters.endDate"
                no-title
                class="ma-0"
                @input="menuEndDate = false"
              />
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-autocomplete
              v-model="filters.userId"
              :items="users"
              item-text="name"
              item-value="id"
              label="Criado por"
              hide-details
              clearable
              :loading="loadingUsers"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            md="6"
          >
            <v-select
              v-model="filters.printed"
              :items="[
                {
                  value: null,
                  description: 'Todos'
                },
                {
                  value: true,
                  description: 'Sim'
                },
                {
                  value: false,
                  description: 'Não'
                },
              ]"
              item-text="description"
              item-value="value"
              label="Impresso"
              hide-details
              :loading="loadingCustomers"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="info"
          outlined
          @click="filter"
        >
          <v-icon
            left
            v-text="'mdi-filter'"
          />
          Pesquisar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import customersApi from '@/api/customers'
  import usersApi from '@/api/users'
  import dateFormat from '@/utils/date-format'

  export default {

    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },

    data () {
      return {
        menuStartDate: false,
        menuEndDate: false,
        loadingCustomers: false,
        loadingUsers: false,
        customers: [],
        users: [],
        filters: {
          startDate: null,
          endDate: null,
          printed: null,
        },
        dateFormatIso: dateFormat.iso,
        formatedStartDate: null,
        formatedEndDate: null,
      }
    },

    computed: {
      show: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        },
      },
    },

    watch: {
      'filters.startDate': {
        immediate: true,
        handler (newVal) {
          this.formatedStartDate = dateFormat.ptBr(newVal)
        },
      },
      'filters.endDate': {
        immediate: true,
        handler (newVal) {
          this.formatedEndDate = dateFormat.ptBr(newVal)
        },
      },
    },

    mounted () {
      this.loadCustomers()
      this.loadUsers()
    },

    methods: {
      async loadCustomers () {
       try {
          this.loadingCustomers = true

          const response = await customersApi.list()

          this.customers = response.data.customers
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loadingCustomers = false
        }
      },

      async loadUsers () {
       try {
          this.loadingUsers = true

          const response = await usersApi.list()

          this.users = response.data.users
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loadingUsers = false
        }
      },

      filter () {
        this.show = false

        if (!this.filters.startDate) {
          delete this.filters.startDate
        }

        if (!this.filters.endDate) {
          delete this.filters.endDate
        }

        this.$emit('filter', this.filters)
      },
    },

  }
</script>
